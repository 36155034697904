import { Logger } from "@storefront/core/lib/logger";
import { getCurrentLanguage } from "@storefront/core/i18n";
import config from "@config";
import axios from "axios";
import graphql from "@graphql";
import coreProduct from "@storefront/core/modules/catalog/store/product";
import { getProductBySku, getProductCrossSell, getProductByCategoryId } from "@storefront/core/data-resolver/products";
const state = () => ({
  ...coreProduct.state(),
  bestSellerProducts: [],
  CrossSellProducts: [],
});

const actions = {
  ...coreProduct.actions,

  async getProductFiltersByCategoryId(id) {
    const lang = getCurrentLanguage();
    //Needs to become variable based on language
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];
    const defaultsort = config.sortDefault;

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };
    let query =
      " { products(pageSize: 12  sort: { " +
      defaultsort +
      ': DESC } filter: {category_id: {eq: "' +
      id +
      '"}    ';
    let end_query = "";
    query = query + end_query + "})";
    var productOverview = '';
    productOverview = graphql.queryFields.productOverview.replace('<aggregations>', "aggregations { min_value max_value attribute_code count label options { count label value } }");
    query = query + productOverview + "}";
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error(
        "getProductFiltersByCategoryId",
        "data-resolver products",
        e
      )();
      throw e;
    });
    return retval.data.data.products;
  },
  async loadBestSellers({ commit }) {
    try {
      const categories_object = config.categories;
      const best_seller_category = categories_object["best_seller"];

      // Fetch best seller data
      const data = await getProductByCategoryId(best_seller_category,1);
      // Logger.debug("loadBestSellers","product_local",data)();
      const productDetails = data.items.map((item) => ({
        sku: item.sku,
        imageUrl: item.image.url,
      }));

      // Fetch product details
      const products = await Promise.all(
        productDetails.map(async (detail) => {
          const { sku, imageUrl } = detail;
          const productData = await getProductBySku(sku);

          if (productData && productData.items && productData.items.length) {
            const product = productData.items[0];
            product.image.medium = imageUrl;
            return product;
          }
          return null;
        })
      );

      // Commit processed products to the store
      commit(
        "setBestSellerProducts",
        products.filter((product) => product !== null)
      );
    } catch (error) {
      console.error("Error loading best sellers:", error);
    }
  },
  async loadCrossSellSkuArray({ commit }, { skus }) {
    const products = [];

    try {
      for (const sku of skus) {
        const result = await getProductCrossSell(sku).catch((e) => {
          Logger.error("getProductCrossSell", "product store actions load", e)();
          throw e;
        });

        if (result && result.items && result.items[0]) {
          await result.items[0].crosssell_products.forEach((product) => {
            products.push(product);
          })
        }
      }

      commit("setCrossSell", products);
    } catch (error) {
      console.error("Error loading cross-sell products:", error);
    }
  },
};

const mutations = {
  ...coreProduct.mutations,
  setBestSellerProducts(state, products) {
    state.bestSellerProducts = products;
  },
  setCrossSell(state, data) {
    state.CrossSellProducts = data;
  },
};

const getters = {
  ...coreProduct.getters,
  getBestSellerProducts: (state) => state.bestSellerProducts,
  getCrossSellSkuArray: (state)=> state.CrossSellProducts,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
